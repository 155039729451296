
import { plainToClass } from 'class-transformer';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import HeartOutlineIcon from '@/assets/icons/heart-outline.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import ZaltoDetailItems from '@/components/ZaltoDetailItems.vue';
import Button from '@/shared/components/Button.vue';
import LogoDivider from '@/shared/components/LogoDivider.vue';
import { Zalto } from '@/shared/models/Zalto';

export default {
    components: {
        ZaltoDetailItems,
        Button,
        LogoDivider,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const zalto = ref(new Zalto());
        const items = ref(Array<Zalto>());

        function getZalto(id?: string) {
            const zaltoId = id || route.params.id;

            store.dispatch('ZALTO_SHOW', zaltoId).then((res: any) => {
                zalto.value = plainToClass(Zalto, res.data.wine);
                items.value = plainToClass(Zalto, res.data.items as Zalto[]);
            });
        }

        const width = ref(window.innerWidth);

        function handleResize(event: any) {
            width.value = event.target.innerWidth;
        }

        onMounted(() => {
            window.addEventListener('resize', handleResize);
            getZalto();
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        function scrollIntoView(item: any) {
            const element = document.querySelector(`#${item}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return {
            zalto,
            items,
            width,
            scrollIntoView,
            HeartIcon,
            HeartOutlineIcon,
        };
    },
};
