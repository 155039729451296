import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wine-detail-container container pb-5"
}
const _hoisted_2 = { class: "d-flex flex-row flex-wrap justify-content-evenly align-items-start" }
const _hoisted_3 = { class: "text-start text-lg-end" }
const _hoisted_4 = { class: "mt-2 text-start text-lg-end text-wrap red" }
const _hoisted_5 = {
  key: 0,
  class: "pt-1 d-block text-start d-lg-none"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "info-block text-start" }
const _hoisted_8 = {
  key: 0,
  class: "pt-5 d-none d-lg-block"
}
const _hoisted_9 = {
  key: 0,
  id: "options"
}
const _hoisted_10 = { class: "text-start text-lg-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_LogoDivider = _resolveComponent("LogoDivider")!
  const _component_ZaltoDetailItems = _resolveComponent("ZaltoDetailItems")!

  return ($setup.zalto && Object.keys($setup.zalto).length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createTextVNode(_toDisplayString($setup.zalto.name) + " ", 1),
            _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.zalto.summary), 1),
            ($setup.items)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    text: $setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie'),
                    design: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.scrollIntoView('options')))
                  }, null, 8, ["text"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("img", {
            class: "wine-bottle",
            src: $setup.zalto.image_large,
            alt: "wine"
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-block border-bottom" }, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border-bottom d-flex flex-row justify-content-between py-3" }, [
              _createElementVNode("div", { class: "d-block w-100" }, [
                _createElementVNode("a", {
                  class: "btn-clean",
                  href: "http://www.zaltoglas.at/",
                  target: "_blank"
                }, " Bezoek website ")
              ])
            ], -1)),
            ($setup.items)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    text: $setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie'),
                    design: "primary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.scrollIntoView('options')))
                  }, null, 8, ["text"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_LogoDivider, { class: "d-none d-lg-block" }),
        ($setup.items)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("h2", _hoisted_10, _toDisplayString($setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie')), 1),
              _createVNode(_component_ZaltoDetailItems, {
                items: $setup.items,
                zalto: $setup.zalto
              }, null, 8, ["items", "zalto"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}